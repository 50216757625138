import request from '@/utils/request'

//获取门户推荐虚拟仿真列表
export function getVRIndexList(params) {
    return request({
        url: '/portal/vr/get-index-list',
        method: 'GET',
        params
    })
}

//获取虚拟仿真列表
export function getVRList(params) {
    return request({
        url: '/portal/vr/get-list',
        method: 'GET',
        params
    })
}

//获取虚拟仿真详情 包含项目简介，项目引导视频等头部信息以及
//项目介绍 项目团队 实验指南等信息
export function getVRInfo(params) {
    return request({
        url: '/portal/vr/get-info',
        method: 'GET',
        params
    })
}

//获取虚拟仿真列表筛选项
export function getVRfilter(params) {
    return request({
        url: '/portal/vr/get-filter-fields',
        method: 'GET',
        params
    })
}

//获取我参与的虚拟仿真列表
export function getVRMyList(params) {
    return request({
        url: '/portal/vr/get-my-list',
        method: 'GET',
        params
    })
}

//虚拟仿真实验资料列表
export function getVRAttachments(params) {
    return request({
        url: '/portal/vr/get-attachments',
        method: 'GET',
        params
    })
}



//实验资源 获取虚拟仿真模块列表
export function getVRModuleList(params) {
    return request({
        url: '/portal/vr-module/get-list',
        method: 'GET',
        params
    })
}
  // 获取虚拟仿真模块访问地址
export function getvrmodulelink(params) {
    return request({
      url: '/portal/vr-module/get-link',
      method: 'GET',
      params
    })
  }    

//获取虚拟仿真项目团队列表
export function getvrteams(params) {
    return request({
        url: '/portal/vr/get-teams',
        method: 'GET',
        params
    })
}


//远程视频指导
//获取虚拟仿真项目团队列表
export function remoteGetList(params) {
    return request({
        url: '/common/camera/get-list',
        method: 'get',
        params
    })
}

//远程视频指导
//获取虚拟仿真项目团队列表
export function set_remote(params) {
    return request({
        url: '/common/camera/set-remote',
        method: 'post',
        params
    })
}

//保存老平台虚拟仿真用户信息
export function oldPlatformUser(params) {
    return request({
        url: '/portal/old-platform-user/add',
        method: 'post',
        params
    })
}
//请求莱医特登录接口
export function oldLogin(params) {
    return request({
        url: 'http://xnfz.hzau.edu.cn/api/User/Login/',
        method: 'post',
        headers:{
            'Content-Type': 'application/json;charset=UTF-8'
        },
        params
    })
}


//获取各实验台的监控画面
export function remoteLessonList(params) {
    return request({
        url: '/common/camera/get-lesson-camera-list',
        method: 'get',
        params
    })
}


export function getCourseVrModuleList(params) {
    return request({
        url: '/portal/vr-module/get-course-vr-module-list',
        method: 'get',
        params
    })
}
//获取学校列表
export function getSchoolList(params) {
    return request({
        url: '/common/school/get-list',
        method: 'get',
        params
    })
}
//学校认证
export function userDoAuth(params) {
    return request({
        url: '/portal/user/do-auth',
        method: 'post',
        params
    })
}





