<template>
  <div class="login-container">
    <div class="centercontain">
      <div class="left">
        <el-image class="backimage" :src="icon2" />
        <div class="logocintain">
          <el-image class="logo" :src="icon6" />
        </div>
      </div>

      <div class="right">
        <div class="logoitem" style="width: 300px; margin: 80px auto 0px;">
          <el-image class="logo" :src="icon1" />
        </div>
        <div class="logoitem logoitem2">
          <div :class="loginClass" @click="switchLogin('1')">
            <span>账号密码登录</span>
          </div>
          <div :class="phoneClass" @click="switchLogin('2')">
            <span>手机号登录</span>
          </div>
          <!-- <el-image class="logo" :src="icon7" /> -->
        </div>
        <el-form ref="loginForm" :rules="loginRules" :model="loginForm" class="elform">
          <template v-if="loginForm.switchNum == 1">
            <el-form-item label="" class="elitem" prop="school_sn">
              <div class="inputcontain">
                <el-image class="user" :src="icon8" />
                <el-select v-model="loginForm.school_sn" class="elinput" filterable placeholder="请选择学校" @focus="selectSchool"
                  style="width:100%">
                  <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.school_sn">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="" class="elitem" prop="facultyNo">
              <div class="inputcontain">
                <el-image class="user" :src="icon5" />
                <el-input class="elinput" v-model.trim="loginForm.facultyNo" placeholder="学生学号/教师工号" clearable>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="" class="elitem elitem2" prop="facultyPassword">
              <div class="inputcontain">
                <el-image class="user" :src="icon3" />
                <el-input class="elinput" v-model.trim="loginForm.facultyPassword" placeholder="请输入密码" show-password clearable>
                </el-input>
              </div>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="" class="elitem" prop="username">
              <div class="inputcontain">
                <el-image class="user" :src="icon5" />
                <el-input class="elinput" v-model.trim="loginForm.username" placeholder="请输入手机号" clearable maxlength="11">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="" class="elitem elitem2" prop="password">
              <div class="inputcontain">
                <el-image class="user" :src="icon3" />
                <el-input class="elinput" v-model.trim="loginForm.password" placeholder="请输入密码" show-password clearable>
                </el-input>
              </div>
            </el-form-item>
          </template>
        </el-form>

        <div class="logoitem passtip">
          <div class="contain">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <div class="el-checkbox__label">
              <div class="logoitem passtip">
                <div class="contain_reg" @click="getforgotPassword">
                  <span>忘记密码？</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logoitem logbt">
          <el-button class="bt" v-if="loading"><span class="el-icon-loading loadingIcon"></span><span
              class="loadingText">正在登录中</span></el-button>
          <el-button class="bt" v-else @click="handleLogin">登录</el-button>
          <!-- <el-button @click="authentication">统一身份认证</el-button> -->
        </div>
        <div class="register">
          <span>还没账户？</span>
          <span style="color:#0773FC;cursor: pointer;" @click="register">去注册</span>
        </div>
        <!-- <div class="logoitem logbt">
          <el-button class="bt" @click="authentication">统一身份认证</el-button>
        </div> -->
      </div>
    </div>
    <el-dialog title="忘记密码" :visible.sync="forgotVisible" width="20%" top="30vh">
      <div class="right">
        <el-form ref="loginForgot" :rules="forgotRules" :model="loginForgot" class="elform">
          <el-form-item label="" class="elitem" prop="mobile" style="margin-top: 40px">
            <div class="inputcontain">
              <el-input placeholder="请输入手机号" v-model="loginForgot.mobile" type="text" class="elinput" maxlength="11">
                <template slot="prepend">+86</template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="" class="elitem" prop="sms_code">
            <div class="inputcontain_1">
              <el-input placeholder="请输入验证码" v-model="loginForgot.sms_code" type="text" class="elinput">
                <template slot="append" v-if="show">
                  <div @click="getCode">
                    <span>获取验证码</span>
                  </div>
                </template>
                <template slot="append" v-if="!show">
                  <div @click="getCode" class="getCode">
                    <span>{{ count + "s" }}后重发</span>
                  </div>
                </template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="" class="elitem elitem2" prop="password">
            <div class="inputcontain">
              <el-image class="user" :src="icon3" />
              <el-input class="elinput" v-model="loginForgot.password" placeholder="请输入密码" show-password clearable>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button type="primary" class="bt" @click="authForget">重置</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="首次登录，请您先完善信息" :visible.sync="authenticationVisible" width="20%" top="30vh">
      <div class="right">
        <el-form ref="loginForgot" :rules="forgotRules" :model="loginForgot" class="elform">
          <el-form-item label="" class="elitem" prop="mobile" style="margin-top: 40px">
            <div class="inputcontain">
              <el-input placeholder="请输入手机号" v-model="loginForgot.mobile" type="text" class="elinput" maxlength="11">
                <template slot="prepend">+86</template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="" class="elitem" prop="sms_code">
            <div class="inputcontain_1">
              <el-input placeholder="请输入验证码" v-model="loginForgot.sms_code" type="text" class="elinput">
                <template slot="append" v-if="show">
                  <div @click="getCode">
                    <span>获取验证码</span>
                  </div>
                </template>
                <template slot="append" v-if="!show">
                  <div @click="getCode" class="getCode">
                    <span>{{ count + "s" }}后重发</span>
                  </div>
                </template>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button type="primary" class="bt" @click="bindMobile">确认</el-button>
        </div>
        <div style="margin-top: 10px; width: 60%; text-align: center;">
          <span>
            完善后，您也可以使用手机号登录哦!
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getuserinfo, sendsmscode, authForget, bindMobile } from "@/api/user";
import { getSchoolList } from "@/api/vr";
import { getschoolinfo } from "@/api/school.js";
import {
  getIdentifierToken,
  setIdentifierToken,
  setWebNameToken,
  setWebAvaterToken,
} from "@/utils/auth";
import md5 from "js-md5";
export default {
  name: "Login",
  components: {},
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("请输入正确的登录密码"));
      } else {
        callback();
      }
    };
    const codeCheck = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入正确的验证码!"));
      } else {
        callback();
      }
    };
    return {
      btid: -1,
      dialogVisible: false,
      forgotVisible: false,
      authenticationVisible: false,
      count: "",
      show: true,
      identifiers: "",
      icon1: require("@/assets/login/ujoylogo.png"),
      icon2: require("@/assets/login/ujoywzbg.png"),
      icon3: require("@/assets/login/ujoypwd.png"),
      icon5: require("@/assets/login/ujoyuser.png"),
      icon6: require("@/assets/login/text.png"),
      icon7: require("@/assets/login/ujoyline.png"),
      icon8: require("@/assets/login/selectSchool.png"),
      checked: false,
      loginClass: '',
      phoneClass: '',
      schoolList: [],//学校列表
      // loading:'true',
      loginForm: {
        school_sn: '',
        username: "", // 手机号格式
        password: "",
        facultyNo: '',
        facultyPassword:'',
        switchNum: 1,
      },
      loginForgot:{
        mobile: "", // 手机号格式
        password: "",
        sms_code: "",
      },
      loginRules: {
        school_sn: [
          { required: true, message: "请选择学校", trigger: ["blur","change"] },
        ],
        facultyNo:[
          {
            required: true,
            message: "请输入学生学号/教师工号",
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 11,
            message: "手机号码长度不能超过11个字符",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]{6,11}$/,
            message: "请输入正确的手机号码",
          },
          {
            pattern: /^1[3|4|5|6|7|8][0-9]{9}$/,
            message: "请输入正确的手机号码",
          },
        ],
        facultyPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      forgotRules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 11,
            message: "手机号码长度不能超过11个字符",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]{6,11}$/,
            message: "请输入正确的手机号码",
          },
          {
            pattern: /^1[3|4|5|6|7|8][0-9]{9}$/,
            message: "请输入正确的手机号码",
          },
        ],
        sms_code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            required: true,
            validator: codeCheck,
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            // pattern: /^[0-9A-Za-z]{6,16}$/,
            min: 6,
            max: 16,
            message: "请输入6~16位密码",
          },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      schoolinfo: {},
    };
  },

  created() {
    // let info = localStorage.getItem("schoolinfo");
    // if (info) {
    //   this.schoolinfo = JSON.parse(info);
    // } else {
    //   getschoolinfo({}).then((res) => {
    //     if (res && res.code == 0) {
    //       this.schoolinfo = res.data;
    //     }
    //   });
    // }
    this.loginClass = 'pdlogin'
    this.phoneClass = 'pdlogin phonelogin'
    // this.getSchoolList()
  },
  mounted() {
    this.getCookie()
  },
  methods: {
    selectSchool(){
      this.getSchoolList()
    },

    //验证码
    getCode() {
      if (this.loginForgot.mobile && this.loginForgot.mobile.length === 11) {
        const time = new Date().getTime();
        const params = {
          mobile: this.loginForgot.mobile,
          sign: md5(md5(this.loginForgot.mobile) + time),
          time: time,
        };
        sendsmscode(params)
          .then((response) => {
            const { data } = response;
            if (response.code === 0) {
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else if (response.data && response.data.sms_code) {
              this.loginForgot.sms_code = response.data.sms_code;
            } else {
              this.$message({
                message: response.message,
                type: "error",
              });
            }
          })
          .catch((error) => { });
      } else {
        this.$message({
          message: "请输入正确的手机号码!",
          type: "error",
        });
      }
    },
    //重置密码
    authForget(){
      if (this.loginForgot.sms_code) {
        const params = {
          mobile: this.loginForgot.mobile,
          password: this.loginForgot.password,
          sms_code: this.loginForgot.sms_code,
        };
        authForget(params).then((response) => {
          if (response.code === 0) {
            this.$message({
              message: "密码重置成功，请重新登录",
              type: "success",
            });
            this.forgotVisible = false
          }else{
            this.$message({
              message: "短信验证码错误!",
              type: "error",
            });
            // 错误后验证码置空
            this.loginForgot.sms_code = "";
            this.codeCheck();
            
          }
        })
      } else {
        this.$message({
          message: '请先获取验证码!',
          type: 'error'
        })
      }
      
    },
    //绑定手机号
    bindMobile(){
      const params = {
        mobile: this.loginForgot.mobile,
        sms_code: this.loginForgot.sms_code,
      };
      bindMobile(params).then((res) =>{
        if(res.code == 0){
          this.$message({
            message: "手机号绑定成功",
            type: "success",
          });
          this.authenticationVisible = false
        }
      })
    },
    btclick(id) {
      this.btid = id;
    },
    //登录方式切换
    switchLogin(el) {
      if (el == 1) {
        // alert(el)
        this.loginClass = 'pdlogin '
        this.phoneClass = 'pdlogin phonelogin'
        this.loginForm.switchNum = el
      } else if (el == 2) {
        this.loginClass = 'pdlogin phonelogin'
        this.phoneClass = 'pdlogin '
        this.loginForm.switchNum = el
      }
    },
    //忘记密码
    getforgotPassword() {
      this.forgotVisible = true
    },
    //获取学校列表
    getSchoolList() {
      getSchoolList().then((response) => {
        if (response.code == 0) {
          this.schoolList = response.data
        }
      })
    },
    // 登录
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch("login", this.loginForm).then((res) => {
            // console.log('resresresresres', res);
            this.getuserinfo();
            this.dialogVisible = true;
            if (this.checked == true) {
              //传入账号名，密码，和保存天数3个参数
              this.setCookie(
                this.loginForm.username,
                this.loginForm.password,
                7
              );
            } else {
              //清空Cookie
              this.clearCookie();
            }
            this.loading = false;
          })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    //设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    //读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //再次切割
          //判断查找相对应的值
          if (arr2[0] == "userName") {
            this.loginForm.username = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == "userPwd") {
            this.loginForm.password = arr2[1];
            this.checked = true
          }
        }
      }
    },
    //清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    },
    getuserinfo(params) {
      getuserinfo(params)
        .then((response) => {
          if (response.code == 0) {
            this.$store.commit("SET_Username", response.data.name);
            setWebNameToken(response.data.name);
            this.$store.commit("SET_Useravater", response.data.avatar);
            setWebAvaterToken(response.data.avatar);
            this.$router.push({ path: "/home" });
            localStorage.setItem('live_login_flag', "0")
          } else if (response.code == 433){
            this.authenticationVisible = true
          } else if (response.code == 431){
            this.$router.push({ path: "/home" });
            localStorage.setItem('authentication', "1")
          }
        })
        .catch((error) => { });
    },
    //用户注册
    register() {
      this.$router.push({
        path: "/register",
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  width: 100%;
  position: relative;
  background: url("../../assets/login/ujoybj.png") 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .centercontain {
    height: auto;
    display: flex;
    width: 1286px;

    .left {
      position: relative;
      width: 481px;

      .logocintain {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .backimage {
        margin-bottom: -4px;
        height: 100%;
      }
    }

    .right {
      flex: 1;
      background: #ffffff;

      .logoitem {
        // margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        .inputcontain {
          width: 411px;
          height: 60px;
          border: 1px solid #3D84FF;
          border-radius: 10px;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .user {
            margin-left: 20px;
          }

          .elinput {
            ::v-deep .el-input__inner {
              border: none;
            }
          }
        }
      }

      .logoitem2 {
        margin-top: 30px;
        width: 83%;

        .pdlogin {
          width: 20%;
          text-align: left;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          height: 40px;
          line-height: 40px;
        }

        .phonelogin {
          color: #8693AB;
        }
      }

      .elform {
        .elitem {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          .inputcontain {
            width: 411px;
            height: 60px;
            border: 1px solid #3D84FF;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .user {
              margin-left: 20px;

              width: 27px;
              height: 27px;
            }

            .elinput {
              ::v-deep .el-input__inner {
                border: none;
              }
            }
          }
        }

        .elitem2 {
          margin-top: 31px;
        }
      }

      .register {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .passtip {
        // margin-top: 10px;

        .contain {
          width: 411px;
          height: 30px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding-left: 14px;
          padding-right: 14px;

          .el-checkbox__label {
            cursor: pointer;
          }
        }

        .contain_reg {
          cursor: pointer;
        }
      }

      .logbt {
        margin-top: 25px;

        .bt {
          width: 411px;
          height: 60px;
          color: white;
          background: #3D84FF;

          border-radius: 10px;
        }

        .loadingIcon {
          font-size: 20px;
        }

        .loadingText {
          transform: translateY(-2px);
          display: inline-block;
        }
      }
    }

  }

  .elform {
    .elitem {
      // border: 1px solid red;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      .inputcontain {
        width: 411px;
        height: 60px;
        border: 1px solid #3D84FF;
        border-radius: 10px;
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .user {
          // border: 1px solid red;
          margin-left: 20px;
          // margin-right: 4px;
          width: 27px;
          height: 27px;
        }

        .elinput {

          // border: 1px solid red;
          ::v-deep .el-input__inner {
            border: none;
            height: 60px;
            border-radius: 0px 10px 10px 0px;
          }
        }

        ::v-deep .el-input-group__prepend {
          border: none;
          color: #000;
          height: 60px;
          border-radius: 10px 0 0 10px;
        }
      }

      .inputcontain_1 {
        width: 411px;
        height: 60px;
        border: 1px solid #3D84FF;
        border-radius: 10px;
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .user {
          // border: 1px solid red;
          margin-left: 20px;
          // margin-right: 4px;
          width: 27px;
          height: 27px;
        }

        ::v-deep .el-input-group__append {
          border: none;
          color: #000;
          height: 60px;
          border-radius: 0px 10px 10px 0px;
          cursor: pointer;
          width: 60px;
          text-align: center;
        }

        ::v-deep .el-input__inner {
          border: none;
          height: 60px;
          border-radius: 10px 0px 0px 10px;
        }

        .getCode {
          color: #3D84FF;
        }
      }
    }

    .elitem2 {
      margin-top: 31px;
    }
  }
    .bt {
      width: 90%;
      height: 60px;
      color: white;
      background: #3D84FF;
      border-radius: 10px;
    }
}
</style>
