import request from '@/utils/request'

//获取学校列表
export function getschoollist(params) {
    return request({
        url: '/common/school-join/get-list',
        method: 'GET',
        params
    })
}

//获取学校列表
export function getschoolinfo(params) {
    return request({
        url: '/common/school/get-info',
        method: 'GET',
        params
    })
}

// //退出登录
// export function loginOut(params) {
//     return request({
//         url: '/common/auth/logout',
//         method: 'GET',
//         params
//     })
// }

